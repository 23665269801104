@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@crystal-eyes/meetings/styles/themes/clear-light.css';
@import '@crystal-eyes/meetings/styles/themes/clear-disc.css';

@layer base {
  html {
    scroll-behavior: smooth;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 120%;
    letter-spacing: -0.06em;
  }

  h2 {
    font-size: 2.25rem;
    line-height: 120%;
    letter-spacing: -0.05em;
  }

  h3 {
    font-size: 2rem;
    line-height: 120%;
    letter-spacing: -0.05em;
  }

  h4 {
    font-size: 1.5rem;
    line-height: 120%;
    letter-spacing: -0.04em;
  }

  h5 {
    font-size: 1.25rem;
    line-height: 120%;
    letter-spacing: -0.04em;
  }

  h6 {
    font-size: 1.125rem;
    line-height: 120%;
    letter-spacing: -0.03em;
  }

  p {
    font-size: 1rem;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 3.5rem;
    }

    h2 {
      font-size: 3rem;
      letter-spacing: -0.06em;
    }

    h3 {
      font-size: 2.5rem;
      letter-spacing: -0.06em;
    }

    h4 {
      letter-spacing: -0.05em;
      font-size: 2rem;
    }

    h5 {
      font-size: 1.5rem;
    }

    h6 {
      font-size: 1.25rem;
      letter-spacing: -0.04em;
    }
  }

  main {
    min-height: var(--content-min-height);
  }

  body {
    margin: 0 0;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    font-family: var(--font-inter);
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
  }

  a {
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  a.disabled {
    &:hover {
      text-decoration: none;
    }
  }

  label {
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: var(--color-text-primary);
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: var(--color-neutral-lighter);
  border-radius: 9999px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--color-neutral-lighter);
}
